<template>
<div>
  <div class="product-type" @click="clickEvent(item)" v-for="(item,index) in list" :key="index">
    <p class="product-type-title">{{item.title}}</p>
    <div class="product-type-con">
      <img :src="item.img_url" alt="" :onerror="errorImage">
      <div>
        <p class="intro">{{item.desc}}</p>
        <p class="redColor">{{item.price}}</p>
      </div>
    </div>

  </div>
</div>

</template>
<script>
  export default {
    name:"productType",
    props:{
      list:[Array,Object]
    },
    data(){
      return {
        errorImage: 'this.src="' + require('@/assets/images/error_img.jpg') + '"'  ,
      }
    },
    created(){
      console.log("====productType====",this.list)
    },
    methods:{
      clickEvent(item){
        this.$emit("on-click",item.item_url);//将方法抛出给父组件，在父组件中用@on-click来使用
      }
    }
  }
</script>

<style lang="scss" scoped>
  .product-type{
    text-align: left;
    &-title{
      word-break: break-all;
    }
    &-con{
      display: flex;
      align-items: center;
      img{
        display: block;
        width: 60px;
        height: 60px;
        border: none;
        margin-right: 10px;
      }
      .intro{
        height: 50px;
      }
      .redColor{
        color: red;
        // margin-top: 20px;
      }
    }
  }
</style>
